<template>
  <div class="about">
    <!-- 网站顶部 -->
    <OfficialOtherHead :homePageSetting="homePageSetting" />
    <!-- about -->
    <div class="main">
      <!-- 标题 -->
      <div class="content-title">
        <img class="hidden-xs" src="@/assets/img/bg-about.png" />
        <img class="hidden-mx" src="@/assets/img/bg-about@2x.png" />
        <div class="content-box">
          <h1>{{dataModel.companyName}}</h1>
          <div>{{dataModel.companyPurpose}}</div>
        </div>
      </div>
      <!-- 内容 -->
      <div class="panel">
        <div class="container">
          <div class="content">
            <p v-for="(item,index) in dataModel.briefList" :key="index">{{item}}</p>

          </div>
          <div class="share" v-if="false">
            <div class="share-item wecaht"><i></i></div>
            <div class="share-item weibo"><i></i></div>
            <div class="share-item friend"><i></i></div>
          </div>
        </div>
      </div>
    </div>
    <!-- end about -->

    <!-- 网站底部 -->
    <OfficialFoot :homePageSetting="homePageSetting" />
  </div>
</template>

<script>
import OfficialOtherHead from "./OfficialOtherHead";
import OfficialFoot from "./OfficialFoot";
import { getHomePageSetting,setSeo } from "../api/defaultDataModel";
export default {
  name: "about",
  components: {
    OfficialOtherHead,
    OfficialFoot,
  },
  data: function () {
    return {
      homePageSetting: getHomePageSetting(),
      dataModel: {
        id: undefined,
        companyName: undefined,
        companyPurpose: undefined,
        imgUrl:undefined,
        smallImgUrl:undefined,
        linkUrl: null,
        companyType: 2,
        briefList: [],
      },
    };
  },
  created() {
    //当前关于数据
    if (this.$route.query.id && /^\d{15,}$/.test(this.$route.query.id)) {
      this.$http
        .get(this.TzConfig.BASE_URL + "/h5/aboutCompany/getById", {
          params: { id: this.$route.query.id },
        })
        .then((res) => {
          if (8000 === res.data.code) {
            this.dataModel = res.data.data;
          } else {
            this.$router.push({ path: "/" });
          }
        })
        .catch((err) => {
          this.$router.push({ path: "/" });
        });
    } else {
      this.$router.push({ path: "/" });
    }

    //首页数据
    this.$http
      .get(this.TzConfig.BASE_URL + "/h5/homePageSetting/getSetting")
      .then((res) => {
        if (8000 === res.data.code) {
          this.homePageSetting = res.data.data;
          setSeo(this.homePageSetting.seoTitle,this.homePageSetting.seoDescription,this.homePageSetting.seoKeyword)
        }
      })
      .catch((err) => {
      });
  },
  methods: {
    onSubmit() {
    },
  },
};
</script>
<style scoped>
#app {
  background: #f4f6f8;
}
.main {
  margin: 88px auto 0;
  width: 100%;
}
.content-title {
  max-width: 1920px;
  position: relative;
  height: 360px;
  overflow: hidden;
}
.hidden-xs {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.hidden-mx {
  display: none;
}
.content-box {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99;
  display: -webkit-flex;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  padding: 0 68px;
}
.content-box h1,
.content-box div {
  text-align: center;
  color: #fff;
}
.content-box h1 {
  font-size: 40px;
  line-height: 52px;
}
.content-box div {
  margin: 13px 0 0;
  font-size: 16px;
  line-height: 22px;
}
.panel {
  position: relative;
  z-index: 999;
  margin: -68px auto 0;
  padding: 0 0 68px;
  width: 100%;
}
.container {
  margin: 0 auto;
  padding: 65px 100px;
  max-width: 1200px;
  background: #fff;
}
.content {
  width: 100%;
}
.content p {
  margin: 0 auto 20px;
  font-size: 16px;
  line-height: 36px;
}
.content img {
  margin: 0 auto 16px;
  display: block;
  max-width: 100%;
  height: auto;
}
.content span {
  display: inline-block;
  vertical-align: top;
  font-size: 16px;
  line-height: 36px;
  color: rgb(0 0 0 /50%);
}
.share {
  position: relative;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
}
.share:before {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  z-index: 1;
  display: block;
  content: "";
  width: 100%;
  height: 1px;
  background: #eee;
}
.share-item {
  position: relative;
  z-index: 2;
  padding: 0 17px;
  display: inline-block;
  height: 32px;
  background: #fff;
}
.share-item i {
  display: block;
  width: 32px;
  height: 32px;
  opacity: 0.64;
}
.wecaht i {
  background: url(../assets/images/share-wechat.png) no-repeat center/32px;
}
.weibo i {
  background: url(../assets/images/share-weibo.png) no-repeat center/32px;
}
.friend i {
  background: url(../assets/images/share-friend.png) no-repeat center/32px;
}
.link-box {
  display: -webkit-flex;
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  padding: 10px 0;
  width: 100%;
}
.link-box-item {
  display: -webkit-flex;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  width: 100%;
}
.link-box-item span {
  display: inline-block;
  padding: 0 6px 0 0;
  font-size: 18px;
  line-height: 36px;
}
.link-box-item a {
  font-size: 18px;
  line-height: 36px;
}
.footer {
  margin: 0 auto;
}

@media (max-width: 1400px) {
  .container {
    max-width: auto;
    width: 90%;
  }
}

@media (max-width: 990px) {
  .main {
    margin: 64px auto 0;
  }
  .hidden-xs {
    display: none;
  }
  .hidden-mx {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .panel {
    padding: 0 0 40px;
  }
  .container {
    padding: 30px 50px;
  }
}

@media (max-width: 640px) {
  #app {
    background: #fff;
  }
  .content-title {
    height: 5.6rem;
  }
  .content-title img {
    width: 100%;
    height: 5.6rem;
    object-fit: cover;
  }
  .content-box {
    padding: 0.5rem 0 1.92rem;
  }
  .content-box h1,
  .content-box div {
    padding: 0 0.88rem;
  }
  .content-box h1 {
    font-size: 0.64rem;
    height: auto;
    line-height:.78rem
  }
  .content-box div {
    font-size: 0.28rem;
    line-height: 0.4rem;
  }
  .panel {
    margin: -1.92rem 0 0;
    padding: 0;
  }
  .container {
    margin: 0 0.48rem;
    padding: 0.46rem 0.4rem;
    width: calc(100% - 0.96rem);
  }
  .content p {
    margin: 0 auto 0.3rem;
    font-size: 0.36rem;
    line-height: 0.64rem;
  }
  .content img {
    margin: 0 auto 0.2rem;
  }
  .content span {
    font-size: 0.14rem;
    line-height: 0.48rem;
    color: #414141;
  }
  .share-item {
    padding: 0 0.34rem;
    height: 0.64rem;
  }
  .share-item i {
    display: block;
    width: 0.64rem;
    height: 0.64rem;
  }
  .wecaht i {
    background: url(../assets/images/share-wechat.png) no-repeat center/.64rem;
  }
  .weibo i {
    background: url(../assets/images/share-weibo@2x.png) no-repeat center/.64rem;
  }
  .friend i {
    background: url(../assets/images/share-friend@2x.png) no-repeat
      center/.64rem;
  }
  .link-box {
    display: none;
  }
}
</style>
